var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3","lg":"3"}},[_c('v-img',{attrs:{"src":_vm.user.avatar_url}})],1),_c('v-col',{attrs:{"cols":"12","md":"9","lg":"9"}},[_c('ValidationProvider',{attrs:{"name":"Avatar","rules":"image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                   var errors = ref.errors;
                   var valid = ref.valid;
return _c('v-file-input',{attrs:{"accept":"image/png, image/jpeg","placeholder":"Pick an avatar","prepend-icon":"mdi-camera","label":"Avatar","disabled":_vm.disabled,"error-messages":errors,"success":valid},model:{value:(_vm.userData.newAvatar),callback:function ($$v) {_vm.$set(_vm.userData, "newAvatar", $$v)},expression:"userData.newAvatar"}})}}],null,true)})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"First name","rules":"required|alpha|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return _c('v-text-field',{attrs:{"counter":255,"error-messages":errors,"success":valid,"label":"First name","disabled":_vm.disabled},model:{value:(_vm.userData.first_name),callback:function ($$v) {_vm.$set(_vm.userData, "first_name", $$v)},expression:"userData.first_name"}})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Surname","rules":"required|alpha|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return _c('v-text-field',{attrs:{"counter":255,"error-messages":errors,"success":valid,"label":"Surname","disabled":_vm.disabled},model:{value:(_vm.userData.surname),callback:function ($$v) {_vm.$set(_vm.userData, "surname", $$v)},expression:"userData.surname"}})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return _c('v-text-field',{attrs:{"counter":255,"error-messages":errors,"success":valid,"label":"Email","disabled":_vm.disabled},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.disabled)?_c('v-btn',{on:{"click":_vm.edit}},[_vm._v("Update information")]):_vm._e(),_c('state-indicate',{ref:"indicate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var isLoading = ref.isLoading;
return [(!_vm.disabled)?_c('v-btn',{attrs:{"disabled":invalid,"loading":isLoading},on:{"click":_vm.save}},[_vm._v("Save")]):_vm._e()]}}],null,true)})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }