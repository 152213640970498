<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-container>
              <v-row>
                <v-col cols="12" md="3" lg="3">
                  <v-img
                    :src="user.avatar_url">
                  </v-img>
                </v-col>
                <v-col cols="12" md="9" lg="9">
                  <ValidationProvider name="Avatar" rules="image">
                    <v-file-input
                      slot-scope="{
                      errors,
                      valid
                     }"
                      accept="image/png, image/jpeg"
                      v-model="userData.newAvatar"
                      placeholder="Pick an avatar"
                      prepend-icon="mdi-camera"
                      label="Avatar"
                      :disabled="disabled"
                      :error-messages="errors"
                      :success="valid"
                    ></v-file-input>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col
            cols="12"
          >
            <ValidationProvider name="First name" rules="required|alpha|max:255">
              <v-text-field
                slot-scope="{
                  errors,
                  valid
                }"
                :counter="255"
                v-model="userData.first_name"
                :error-messages="errors"
                :success="valid"
                label="First name"
                :disabled="disabled"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
          >
            <ValidationProvider name="Surname" rules="required|alpha|max:255">
              <v-text-field
                slot-scope="{
                  errors,
                  valid
                }"
                :counter="255"
                v-model="userData.surname"
                :error-messages="errors"
                :success="valid"
                label="Surname"
                :disabled="disabled"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
          >
            <ValidationProvider name="Email" rules="required|email">
              <v-text-field
                slot-scope="{
                  errors,
                  valid
                }"
                :counter="255"
                v-model="userData.email"
                :error-messages="errors"
                :success="valid"
                label="Email"
                :disabled="disabled"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
          >

            <v-btn v-if="disabled" @click="edit">Update information</v-btn>
            <state-indicate ref="indicate" v-slot:default="{isLoading}">
              <v-btn v-if="!disabled" @click="save" :disabled="invalid" :loading="isLoading">Save</v-btn>
            </state-indicate>
          </v-col>

        </v-row>
      </v-container>
    </ValidationObserver>
  </div>
</template>

<script>
    import {mapState} from 'vuex';
    import File from "../../helpers/File";
    import {ValidationProvider, ValidationObserver, withValidation} from 'vee-validate';
    import StateIndicate from "../../components/utils/StateIndicate";
    import ProfileApi from '@/api/profile';

    export default {
        name: "CustomerInfo",
        data() {
            return {
                userData: {
                    first_name: '',
                    surname: '',
                    email: '',
                    avatar: '',
                    newAvatar: null
                },
                disabled: true,
                headers: [
                    {value: 'key'},
                    {value: 'value'},
                ],
                profileInformation: [],
            }
        },
        computed: {
            ...mapState({
                'user': state => state.auth.user,
            })
        },
        mounted() {
            this.initProfileInformation()
        },

        methods: {
            initProfileInformation() {
                this.userData.first_name = this.user.additional_data.first_name
                this.userData.surname = this.user.additional_data.surname
                this.userData.email = this.user.email
            },

            async save() {
                if (await this.$refs.observer.validate()) {
                    let data = this.getFormData();
                    let saving = ProfileApi.updateCustomer(data).then(response => {
                        this.$store.dispatch('auth/getProfileData', null, {root: true});
                        this.disabled = true
                    }).catch(response => {
                        alert('Error')
                    });

                    this.$refs.indicate.usePromise(saving)
                }
            },

            edit() {
                this.disabled = false
            },

            getFormData() {
                let formData = new FormData;
                formData.set('first_name', this.userData.first_name)
                formData.set('surname', this.userData.surname)
                formData.set('email', this.userData.email)

                if (this.userData.newAvatar) {
                    formData.set('avatar', this.userData.newAvatar)
                }

                return formData
            }
        },

        components: {
            ValidationProvider, ValidationObserver, StateIndicate
        },
    }
</script>

<style scoped>

</style>
