import axios from "axios";

export default {
  updateAuthor(data) {
    return axios.post('/api/profile/update-author', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  updateCustomer(data) {
    return axios.post('/api/profile/update-customer', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  update(data) {
    return axios.post('/api/profile/update', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}
